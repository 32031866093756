<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <template>
      <b-breadcrumb id="row0" :items="breadCrumbs"></b-breadcrumb>
    </template>

    <v-banner sticky dark px-6>
      <v-row id="row1">
        <v-col cols="3">
          <BackActionBar @goback="goback"></BackActionBar>
        </v-col>
        <v-col cols="6">
          <ContextualActionBar
            :subtitle="tad.serviceName"
            :title="serviceType"
          ></ContextualActionBar>
        </v-col>
        <v-col cols="3" class="pr-3 pl-0 pt-6 text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="goToDetailNewTab()"
              >
                <v-icon>mdi-card-text-outline</v-icon>
              </v-btn>
            </template>

            <span>VISUALIZZA SCHEDA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="updateDialogInfo(true)"
                ><v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <span>INFO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="exportTad()"
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="returnSLRLink(cameCod)"
                >SLR
              </v-btn>
            </template>
            <span>SLR</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="print"
                ><v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>STAMPA</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row id="row2">
        <div class="row-container">
          <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                icon
                v-bind="attrs"
                v-on="on"
                class="mt-2"
                @click="goToCerts()"
              >
                <v-icon :color="calcColor(tad, true)"> mdi-certificate </v-icon>
              </v-btn>
            </template>
            <span> {{ calcCertStatus(tad) }} </span>
          </v-tooltip>
          <v-row class="no-margin">
            <v-col cols="3" class="textSize">
              <span class="secondary--text"> Referente Operativo: </span>
              <span>
                {{ tad.referenteOperativo ? tad.referenteOperativo : "N/A" }}
              </span>
            </v-col>
            <v-col cols="1" class="textSize">
              <span class="secondary--text"> Area: </span>
              <span>{{ tad.dandD ? tad.dandD : "N/A" }}</span>
            </v-col>
            <v-col cols="2" class="textSize">
              <span class="secondary--text"> Location Servizio: </span>
              <v-tooltip
                bottom
                :open-delay="500"
                v-if="tad.location && tad.location.length > 30"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    v-html="shortenTadLocation(tad.location, 30)"
                  ></span>
                </template>
                <span v-html="tad.location ? tad.location : 'N/A'"></span>
              </v-tooltip>
              <span v-else v-html="tad.location ? tad.location : 'N/A'"></span>
            </v-col>
            <v-col cols="2" class="textSize">
              <v-select
                v-model="selectedShow"
                :items="showItem"
                item-text="label"
                item-value="value"
                outlined
                label="Visualizza"
                dense
                hide-details
                class="pt-2"
                v-on:change="openAll(selectedShow)"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedNodeLifecycle"
                :items="nodeLifecycle"
                item-text="label"
                item-value="value"
                outlined
                label="Stato Nodi"
                dense
                hide-details
                multiple
                class="pt-2"
                v-on:change="changeStatus('node', selectedNodeLifecycle)"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedRsLifecycle"
                :items="rsLifecycle"
                item-text="label"
                item-value="value"
                outlined
                label="Stato Software"
                dense
                hide-details
                multiple
                class="pt-2"
                v-on:change="changeStatus('rs', selectedRsLifecycle)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-sheet id="scrolling-techniques-7" class="pt-0" color="accent">
        <v-row justify="center" v-if="notData">
          <v-col cols="12" class="notDataCss">Nessun dato trovato</v-col>
        </v-row>
        <perfect-scrollbar>
          <v-row style="padding: 0 20px; margin-bottom: 20px" v-if="!notData">
            <v-col class="padd">
              <v-row justify="center" v-for="(item, i) in tad.tad" :key="i">
                <v-expansion-panels
                  multiple
                  :value="allPanels"
                  style="background: rgb(192 192 192 / 25%)"
                >
                  <v-expansion-panel style="background: rgb(192 192 192 / 0%)">
                    <v-expansion-panel-header
                      @click="check(i)"
                      class="firtsHeader"
                    >
                      <v-tooltip bottom :min-width="'80%'" :open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ i }}
                          </span>
                        </template>
                        <span>{{ i }} </span> <br />
                        <span class="titleTooltip"> N° server: </span
                        ><span>{{ calcNumServer(item) }} </span> <br />
                        <span class="titleTooltip"> N° sw: </span
                        ><span>{{ calcNumSw(item) }} </span><br />
                        <span class="titleTooltip">
                          Distinct Location Server:</span
                        ><span v-html="calcLocation(item)"></span>
                      </v-tooltip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-expansion-panels multiple :value="allPanelSoftware">
                          <v-col
                            cols="4"
                            v-for="(header, e) in order(item)"
                            :key="e"
                            style="background: rgb(192 192 192 / 0%)"
                          >
                            <p style="color: white">{{ e }}</p>
                            <div
                              v-if="checkServer(header)"
                              style="color: white"
                            >
                              Nessun server
                            </div>
                            <v-expansion-panel
                              v-for="(server, j) in header"
                              :key="j"
                              style="background: rgb(192 192 192 / 0%)"
                            >
                              <v-expansion-panel-header
                                :class="setBkg(server[0].nodeLifecycleStatus)"
                              >
                                <v-tooltip
                                  bottom
                                  :min-width="'20%'"
                                  :open-delay="500"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <p
                                      class="rowPadding"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ j }}
                                      -
                                      <img
                                        class="imgDimension"
                                        :src="
                                          require('../assets/' +
                                            setIcon(server))
                                        "
                                      />
                                      -
                                      <span
                                        v-if="server[0].nodeIsVirtual == 'True'"
                                      >
                                        Virtuale</span
                                      >
                                      <span
                                        v-if="
                                          server[0].nodeIsVirtual !== 'True'
                                        "
                                      >
                                        Fisico</span
                                      >
                                    </p>
                                  </template>
                                  <span class="titleTooltip">
                                    Lifecycle Status:
                                  </span>
                                  <span>{{
                                    server[0].nodeLifecycleStatus
                                      ? server[0].nodeLifecycleStatus
                                      : "N/A"
                                  }}</span>
                                  <br />
                                  <span class="titleTooltip">
                                    Gruppo gestore:
                                  </span>
                                  <span>{{
                                    server[0].nodeOwnerDisplayLabel
                                      ? server[0].nodeOwnerDisplayLabel
                                      : "N/A"
                                  }}</span>
                                  <br />
                                  <span class="titleTooltip"> Location: </span>
                                  <span>{{
                                    server[0].nodeCalculatedLocation
                                      ? server[0].nodeCalculatedLocation
                                      : "N/A"
                                  }}</span>
                                  <br />
                                  <span class="titleTooltip">
                                    Versione dettagliata del SO:
                                  </span>
                                  <span>{{
                                    server[0].nodeOSFriendlyName
                                      ? server[0].nodeOSFriendlyName
                                      : "N/A"
                                  }}</span>
                                </v-tooltip>
                              </v-expansion-panel-header>

                              <!-- <v-expansion-panel-content v-if="hasOneRsDisplayLabel(server)"> -->
                              <v-expansion-panel-content>
                                <div
                                  v-for="(rs, k) in server"
                                  :key="k"
                                  class="rs-container"
                                  :class="setBkg(rs.rsLifecycleStatus)"
                                >
                                  <v-tooltip
                                    bottom
                                    :min-width="'20%'"
                                    :open-delay="300"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <p
                                        v-if="!rs.rsLifecycleStatus"
                                        class="notRScss"
                                      >
                                        NESSUN RUNNING SOFTWARE
                                      </p>
                                      <p
                                        v-if="rs.rsDisplayLabel"
                                        class="pPadding"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <span class="break-word-all">
                                          {{ rs.rsDiscoveredProductName }}
                                          <span>-</span>
                                          {{ splitlabel(rs.rsDisplayLabel) }}
                                        </span>
                                      </p>
                                    </template>

                                    <span class="titleTooltip">
                                      Lifecycle Status:
                                    </span>
                                    <span>{{
                                      rs.rsLifecycleStatus
                                        ? rs.rsLifecycleStatus
                                        : "N/A"
                                    }}</span
                                    ><br />
                                    <span class="titleTooltip"> Gestore: </span>
                                    <span>{{
                                      rs.rsOwnerDisplayLabel
                                        ? rs.rsOwnerDisplayLabel
                                        : "N/A"
                                    }}</span
                                    ><br />
                                    <span class="titleTooltip"> Versione: </span
                                    ><span>{{
                                      rs.version ? rs.version : "N/A"
                                    }}</span>
                                  </v-tooltip>
                                  <v-tooltip
                                    bottom
                                    v-if="
                                      rs.rsLifecycleStatus && rs.rsDisplayLabel
                                    "
                                    :open-delay="500"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        rounded
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon :color="calcColor(rs)">
                                          mdi-certificate
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-if="rs.certificates.length === 0">
                                      Nessun certificato presente
                                    </span>
                                    <div v-if="rs.certificates.length > 0">
                                      <div
                                        v-for="(cert, i) in rs.certificates"
                                        :key="i"
                                        class="certificate-info"
                                      >
                                        <span class="titleTooltip">
                                          Common Name
                                        </span>
                                        <span>{{ cert.commonName }}</span>
                                        <br />
                                        <span class="titleTooltip">
                                          Valid To
                                        </span>
                                        <span>
                                          {{
                                            new Date(
                                              cert.validTo
                                            ).toLocaleString()
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-tooltip>
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-col>
                        </v-expansion-panels>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              <template v-if="tad.tadCloudComponent">
                <v-row
                  justify="center"
                  v-for="(cloudItems, id) in tad.tadCloudComponent"
                  :key="id"
                >
                  <v-expansion-panels
                    multiple
                    :value="allPanels"
                    style="background: rgb(192 192 192 / 25%)"
                  >
                    <v-expansion-panel
                      style="background: rgb(192 192 192 / 0%)"
                    >
                      <v-expansion-panel-header class="firtsHeader">
                        <span>{{ id }} </span>
                        <!-- <v-tooltip bottom :min-width="'80%'" :open-delay="500">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ i }}
                            </span>
                          </template>
                          <span>{{ i }} </span>
                          <br />
                          <span class="titleTooltip"> Title: </span>
                          <span> text </span>
                        </v-tooltip> -->
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-expansion-panels
                            multiple
                            :value="allPanelSoftware"
                          >
                            <v-row
                              class="c-item"
                              v-for="(item, i) in cloudItems"
                              :key="i"
                              :class="
                                setBkg(item.cloudComponentLifecycleStatus)
                              "
                              ><!-- <v-col md="12" lg="6" class="c-item-propriety">
                                <span class="c-item-propriety-title">
                                  UcmdbId:
                                </span>
                                {{ item.cloudComponentUcmdbId }}
                              </v-col> -->
                              <!-- <v-col md="12" lg="6" class="c-item-propriety">
                                <span class="c-item-propriety-title">
                                  BA:
                                </span>
                                {{ item.baDisplayLabel }}
                              </v-col> -->
                              <v-col md="12" lg="6" class="c-item-propriety">
                                <span class="c-item-propriety-title">
                                  Label:
                                </span>
                                <span
                                  v-if="
                                    item.cloudComponentDisplayLabel.includes(
                                      'http'
                                    )
                                  "
                                >
                                  <a
                                    :href="item.cloudComponentDisplayLabel"
                                    target="_blank"
                                  >
                                    {{ item.cloudComponentDisplayLabel }}
                                  </a>
                                </span>
                                <span v-else>
                                  {{ item.cloudComponentDisplayLabel }}
                                </span>
                              </v-col>
                              <v-col md="12" lg="6" class="c-item-propriety">
                                <span class="c-item-propriety-title">
                                  Lifecycle Status:
                                </span>
                                {{
                                  item.cloudComponentLifecycleStatus.includes(
                                    ":"
                                  )
                                    ? item.cloudComponentLifecycleStatus.split(
                                        ":"
                                      )[1]
                                    : item.cloudComponentLifecycleStatus
                                }}
                              </v-col>
                              <v-col md="12" lg="6" class="c-item-propriety">
                                <span class="c-item-propriety-title">
                                  Location:
                                </span>
                                {{ item.cloudComponentLocation }}
                              </v-col>
                              <v-col md="12" lg="6" class="c-item-propriety">
                                <div class="product-name-container">
                                  <div>
                                    <span class="c-item-propriety-title">
                                      Product Name:
                                    </span>
                                    {{ item.cloudComponentProductName }}
                                  </div>
                                  <v-tooltip
                                    bottom
                                    v-if="
                                      item.cloudComponentLifecycleStatus &&
                                      item.baDisplayLabel
                                    "
                                    :open-delay="500"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        rounded
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon :color="calcColor(item)">
                                          mdi-certificate
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-if="item.certificates.length === 0">
                                      Nessun certificato presente
                                    </span>
                                    <div v-if="item.certificates.length > 0">
                                      <div
                                        v-for="(cert, i) in item.certificates"
                                        :key="i"
                                        class="certificate-info"
                                      >
                                        <span class="titleTooltip">
                                          Common Name
                                        </span>
                                        <span>{{ cert.commonName }}</span>
                                        <br />
                                        <span class="titleTooltip">
                                          Valid To
                                        </span>
                                        <span>
                                          {{
                                            new Date(
                                              cert.validTo
                                            ).toLocaleString()
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </v-tooltip>
                                </div>
                              </v-col>
                              <v-col md="12" lg="6" class="c-item-propriety">
                                <span class="c-item-propriety-title">
                                  Gruppo gestore:
                                </span>
                                {{ item.cloudComponentOwner }}
                              </v-col>
                            </v-row>
                          </v-expansion-panels>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </perfect-scrollbar>
      </v-sheet>
    </v-banner>
    <ModalInfo
      @open-dialog-info="updateDialogInfo"
      :dialogInfo="openDialogInfo"
      :info="infoItems"
    ></ModalInfo>
    <ModalCertificati
      @close-dialog="certsDialogOpen = false"
      :dialogOpen="certsDialogOpen"
    ></ModalCertificati>
  </v-card>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import download from "downloadjs";
import userMixin from "@/mixins/AppBarMenuUser.js";
import BackActionBar from "../components/core/BackActionBar.vue";
import ModalInfo from "@/components/ModalInfo.vue";
import ModalCertificati from "@/components/ModalCertificati.vue";

export default {
  name: "OperatingCardDetailsRS",
  components: {
    ContextualActionBar,
    BackActionBar,
    ModalInfo,
    ModalCertificati,
  },
  mixins: [connectionMixin, userMixin],
  data() {
    return {
      containerMaxHeight: "auto",
      id: this.$route.params.id,
      displayProgress: false,
      serviceType: " ",
      userProfile: "",
      cameCod: "",
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Schede Operative",
          to: { name: "OperatingCards" },
        },
        {
          text: "TAD",
          href: "",
        },
      ],
      panel: [],
      allPanels: [],
      allPanelSoftware: [],
      envSwitch: true,
      nodeLifecycle: ["NODI"],
      showItem: ["BA", "NODI", "RS"],
      selectedShow: "NODI",
      rsLifecycle: [],
      selectedRsLifecycle: ["All"],
      selectedNodeLifecycle: ["All"],
      tad: {},
      notData: false,
      panelsOpened: [],
      openDialogInfo: false,
      infoItems: {},
      certsDialogOpen: false,
    };
  },
  computed: {},
  mounted() {
    this.displayProgress = true;
    this.getUserProfile();
    this.panel = Array.from(Array(this.panel.length).keys());
    this.getNodeLifecycle();
    this.getRsLifecycle();
    this.tadData();
    this.openAll("NODI");
    this.getInfoService(this.id);
    this.containerMaxHeight = this.calcContainerMaxHeight();
    document.documentElement.style.setProperty(
      "--containerMaxHeight",
      this.containerMaxHeight
    );

    window.onafterprint = () => {
      this.resizeContainer();
    };

    window.onresize = () => {
      this.resizeContainer();
    };
  },
  methods: {
    hasOneRsDisplayLabel(server) {
      server.forEach((rs) => {
        if (rs.rsDisplayLabel) {
          return true;
        }
      });
      return false;
    },
    shortenTadLocation(location, maxLength) {
      var regex = /<br>/g,
        result,
        maxIndex = 0;
      while ((result = regex.exec(location))) {
        if (result.index < maxLength) maxIndex = result.index;
      }

      return location.substring(0, maxIndex);
    },
    resizeContainer(size = "") {
      this.containerMaxHeight = size ? size : this.calcContainerMaxHeight();
      document.documentElement.style.setProperty(
        "--containerMaxHeight",
        this.containerMaxHeight
      );
    },
    calcContainerMaxHeight() {
      const el0 = document.getElementById("row0");
      const el1 = document.getElementById("row1");
      const el2 = document.getElementById("row2");

      const height =
        el0.offsetHeight + el1.offsetHeight + el2.offsetHeight + 100;
      return height > 280 ? `calc(100vh - ${height}px)` : "calc(100vh - 280px)";
    },
    calcColor(rs, contrast = false) {
      // primary: "#333",
      // accent: "#444",
      // grigio - nessun certificato presente

      // success: "#4CAF50",
      // verde - stato dei certificati ok

      // secondary: "#fdd300",
      // giallo - sono presenti X certificati in scadenza entro i prossimi 60 giorni

      // error: "#FF5252",
      // rosso - almeno 1 cert scaduto
      // condiz. 1: sono presenti X certificati scaduti
      // condiz. 2: sono presenti X certificati scaduti e Y certificati in scadenza entro i prossimi 60 giorni

      // const colors = ["primary", "success", "secondary", "error"];
      console.log("contrast", contrast);
      let color = contrast ? "#ccc" : "primary";
      if (rs.hasCertificates) {
        if (rs.expiredCertificates > 0) {
          color = "error";
        } else if (rs.expiringCertificates > 0) {
          color = "secondary";
        } else {
          color = "success";
        }
      }

      return color;
    },
    calcCertStatus(rs) {
      let status = "Nessun certificato presente";
      if (rs.hasCertificates) {
        if (rs.expiredCertificates > 0) {
          if (rs.expiredCertificates === 1) {
            status = "E' presente 1 certificato scaduto";
          } else {
            status =
              "Sono presenti " +
              rs.expiredCertificates +
              " certificati scaduti";
          }

          if (rs.expiringCertificates > 0) {
            if (rs.expiringCertificates === 1) {
              status +=
                " e 1 certificato in scadenza entro i prossimi 60 giorni";
            } else {
              status +=
                " e " +
                rs.expiringCertificates +
                " certificati in scadenza entro i prossimi 60 giorni";
            }
          }
        } else if (rs.expiringCertificates > 0) {
          if (rs.expiringCertificates === 1) {
            status =
              "E' presente 1 certificato in scadenza entro i prossimi 60 giorni";
          } else {
            status =
              "Sono presenti " +
              rs.expiringCertificates +
              " certificati in scadenza entro i prossimi 60 giorni";
          }
        } else {
          status = "Stato certificati valido";
        }
      }

      return status;
    },
    goToCerts() {
      this.certsDialogOpen = true;
    },
    async print() {
      this.resizeContainer("auto");
      window.print();
    },
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        this.userProfile = response.data;

        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    goback() {
      this.$router.push("/OperatingCards");
    },
    checkServer(serv) {
      if (Object.keys(serv).length == 0) {
        return true;
      }
    },
    openAll(value) {
      if (value == "NODI") {
        this.allPanels = [0];
        this.allPanelSoftware = [];
      } else if (value == "BA") {
        this.allPanels = [];
        this.allPanelSoftware = [];
      } else if (value == "RS") {
        this.allPanels = [0];
        let array = [];
        let numb = 0;
        Object.values(this.tad.tad).forEach((element) => {
          Object.values(element).forEach((el) => {
            Object.values(el).forEach((e) => {
              // eslint-disable-next-line no-unused-vars
              var res = e;
              numb = numb + 1;
              array.push(numb);
            });
          });
        });
        for (let j = 0; j < array.length; j++) {
          this.allPanelSoftware.push(j);
        }
      }
    },
    check(ev) {
      if (this.arrayPanelsOpened.indexOf(ev) != -1) {
        let idx = this.arrayPanelsOpened.indexOf(ev);
        this.arrayPanelsOpened.splice(idx, 1);

        if (this.arrayPanelsOpened.length == 0) {
          this.envSwitch = true;
        }
      } else {
        this.arrayPanelsOpened.push(ev);
        if (this.arrayPanelsOpened.length == Object.keys(this.tad.tad).length) {
          this.envSwitch = false;
        }
      }
    },
    async getNodeLifecycle() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_NODE_LIFECYCLE"),
          this.opts
        );
        this.nodeLifecycle = response.data;
        this.nodeLifecycle.unshift({ value: "All", label: "Tutte" });
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    async getRsLifecycle() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_RS_LIFECYCLE"),
          this.opts
        );
        this.rsLifecycle = response.data;
        this.rsLifecycle.unshift({ value: "All", label: "Tutte" });
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    changeStatus(select, val) {
      let array = JSON.parse(JSON.stringify(val));
      if (array.length == 0) {
        array = ["All"];
      }
      if (array[0] == "All" && array.length > 1) {
        if (select == "node") {
          let idxnode = this.selectedNodeLifecycle.indexOf("All");
          this.selectedNodeLifecycle.splice(idxnode, 1);
          this.tadData();
        }
        if (select == "rs") {
          let idxRs = this.selectedRsLifecycle.indexOf("All");
          this.selectedRsLifecycle.splice(idxRs, 1);
          this.tadData();
        }
      } else if (select == "node") {
        if (array.length > 0) {
          array.forEach((item) => {
            if (item == "All") {
              this.selectedNodeLifecycle = ["All"];
            }
          });
          this.tadData();
        }
      } else if (select == "rs")
        if (array.length > 0) {
          array.forEach((item) => {
            if (item == "All") {
              this.selectedRsLifecycle = ["All"];
            }
          });
          this.tadData();
        }
    },
    order(value) {
      let newObj = {
        FE: value.FE,
        AS: value.AS,
        DB: value.DB,
      };
      return newObj;
    },
    async tadData() {
      this.displayProgress = true;
      var bodyData = {
        showRs: true,
        nodesLifecycle: this.selectedNodeLifecycle,
        rsLifecycle: this.selectedRsLifecycle,
      };
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_TAD"),

          bodyData,
          this.opts
        );
        this.tad = response.data;
        setTimeout(() => {
          this.resizeContainer();
        }, 300);

        if (
          Object.keys(this.tad.tad).length === 0 &&
          Object.keys(this.tad.tadCloudComponent).length === 0
        ) {
          this.notData = true;
        } else {
          this.notData = false;
        }
        this.displayProgress = false;
      } catch (error) {
        this.displayProgress = false;
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    setBkg(rs) {
      let rs_trim = rs.includes(":") ? rs.split(":")[1] : rs;

      var cssClass = "";
      if (rs_trim === "In use" || rs_trim === "Configured") {
        cssClass = "azzurro";
      } else if (rs_trim === "Assigned to") {
        cssClass = "arancione";
      } else {
        cssClass = "grigio";
      }

      return cssClass;
    },
    calcNumServer(item) {
      let numServer = 0;
      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(item)) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(
          JSON.parse(JSON.stringify(value))
        )) {
          numServer = numServer + 1;
        }
      }
      return numServer;
    },
    calcNumSw(item) {
      let numSw = 0;
      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(item)) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(
          JSON.parse(JSON.stringify(value))
        )) {
          // eslint-disable-next-line no-unused-vars

          value.forEach((el) => {
            if (el.rsLifecycleStatus) {
              numSw = numSw + 1;
            }
          });
        }
      }
      return numSw;
    },
    setIcon(serv) {
      let imgServer = "";
      let stringvalue = JSON.parse(JSON.stringify(serv))[0].nodeOSFriendlyName;
      let strinPlited = stringvalue.split(" ");
      switch (strinPlited[0]) {
        case "AIX":
          imgServer = "ibm_logo.png";
          break;
        case "HP-UX":
          imgServer = "hp_logo.png";
          break;
        case "Microsoft":
          imgServer = "windows_logo.png";
          break;
        case "Oracle":
          imgServer = "oracle_logo.png";
          break;
        case "Red Hat":
          imgServer = "redhat_logo.png";
          break;
        case "SunOS":
          imgServer = "oracle_logo.png";
          break;
        case "SUSE":
          imgServer = "suse_suse_logo.png";
          break;
        case "VMware":
          imgServer = "VMware_logo.png";
          break;
        default:
          imgServer = "linux_logo.png";
      }
      return imgServer;
    },
    calcLocation(item) {
      let location = "";
      var locationArray = [];
      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(item)) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(
          JSON.parse(JSON.stringify(value))
        )) {
          locationArray.push(value[0].nodeCalculatedLocation);
        }
      }
      var unique = locationArray.filter(this.onlyUnique);
      unique.forEach((el, idx) => {
        if (unique.length - 1 !== idx) {
          location = location + el + "<br />";
        } else {
          location = location + el;
        }
      });
      return location;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    goToDetailNewTab() {
      let routeData = this.$router.resolve({
        name: "OperatingCardDetails",
        params: {
          id: this.$route.params.id,
          edit: false,
        },
      });
      window.open(routeData.href, "_self");
    },
    goToEditTab() {
      let routeData = this.$router.resolve({
        name: "OperatingCardDetails",
        params: {
          id: this.$route.params.id,
          edit: true,
        },
      });
      window.open(routeData.href, "_self");
    },
    async exportTad() {
      try {
        this.loading = true;
        this.$emit("overlay-download", this.loading);
        const response = await this.$http.get(
          this.$env.value("API_EXPORT_EXCEL") +
            this.$env.value("API_TAD") +
            "/" +
            this.$route.params.id,
          this.optsXls
        );
        const content = response.headers["application/vnd.ms-Excel"];
        download(response.data, "export-tad" + ".xlsx", content);
        this.loading = false;
        this.$emit("overlay-download", this.loading);
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    async getInfoService(id) {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            id +
            this.$env.value("API_INFO_SERVICE"),
          this.opts
        );
        this.infoItems = response.data;
        this.cameCod = response.data.came;
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    splitlabel(text) {
      const myArray = text.split(" (");
      let word = myArray[0];
      return word;
    },
    updateDialogInfo(value) {
      if (!value) {
        this.shown = false;
      } else {
        this.shown = true;
      }
      this.openDialogInfo = value;
    },
    returnSLRLink(came) {
      var url =
        "https://servicemanager.eni.com/sm9/index.do?ctx=docEngine&file=ENISlrAnag&query=came.code%3D%22" +
        came +
        "%22&action=&title=ENISlrAnag%20" +
        came;
      window.open(url, "_blank");
    },
  },
  watch: {
    allPanels: function (val) {
      return val;
    },
  },
};
</script>
<style scoped>
:root {
  --containerMaxHeight: calc(100vh - 280px);
}

@media print {
  #scrolling-techniques-7,
  .container,
  .ps,
  .row,
  .padd {
    height: auto !important;
    max-height: auto !important;
  }
}

#scrolling-techniques-7,
.ps {
  height: var(--containerMaxHeight);
  max-height: var(--containerMaxHeight);
}

.c-item {
  width: 100%;
  margin: 6px;
  background-color: #999;
  border-radius: 6px;
  padding: 6px;
}

.c-item:first-of-type {
  margin-top: 12px;
}

.c-item-propriety {
  color: black;
  padding: 6px;
}

.c-item-propriety-title {
  font-weight: bold;
}

.no-margin {
  margin: 0;
}

.row-container {
  display: inline-flex;
}

.certificate-info:not(:last-of-type) {
  border-bottom: 1px solid white;
}

div#scrolling-techniques-7 {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.firtsHeader {
  background: #fdd300;
  color: black !important;
}

.secondHeader {
  background: #108124;
  color: rgb(126, 29, 29);
}

.v-expansion-panel {
  padding: 2px;
}

.notDataCss {
  text-align: center;
  padding: 10px;
}

.notRScss {
  background-color: red;
  padding: 10px;
  margin-top: 2px;
  color: white;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.v-expansion-panel-content__wrap {
  padding: 5px !important;
}

button.v-expansion-panel-header.secondHeader {
  padding: 5px 10px !important;
}

.v-expansion-panel-content__wrap {
  padding: 5px 10px !important;
}

.rowPadding {
  padding: 5px 10px;
  margin-bottom: 0;
  color: black;
  font-weight: bold;
  text-align: center;
}

.rs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #606060;
}

.product-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 20px;
}

.break-word-all {
  word-break: break-all;
}

.pPadding {
  padding: 6px;
  margin-bottom: 0;
  color: black;
  font-size: 12px;
  text-align: center;
}

.v-expansion-panel-content__wrap {
  padding: 0 15px 5px !important;
}

.v-expansion-panel-header {
  padding: 5px !important;
  min-height: 50px !important;
}

.textSize {
  font-size: 15px;
}

.azzurro {
  background-color: rgb(119, 203, 255);
}

.arancione {
  background-color: rgb(244, 189, 88);
}

.grigio {
  background-color: rgb(92, 92, 92);
}

.imgDimension {
  width: 30px;
}

.v-tooltip__content {
  font-size: 13px !important;
  opacity: 1 !important;
  background-color: #606060;
}

.titleTooltip {
  color: rgb(244, 189, 88);
  font-weight: bold;
}
</style>
